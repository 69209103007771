<template id="errorPage">
  <div class="page-main-bg">
    <div class="error__page">
      <div class="error__page__content section-1 px-5">
        <div class="error__pic__div">
          <div class="error__pic">
            <img
              src="../assets/404_images/404-img.svg"
              alt="404 Error Image"
              loading="lazy"
            />
          </div>
          <!-- error__pic -->
        </div>
        <!-- error__pic__div -->
        <div class="error__descp__div">
          <div class="error__descp">
            <p class="title">خطأ 404 - لم يتم العثور على الصفحة</p>
            <p data-v-2d667a1c="" class="quote">
              نعتذر لك، لم يتم العثور علي الصفحة المطلوبة يمكنك البدء من الصفحة
              الرئيسية مرة اخري
            </p>
          </div>
          <!-- error__descp -->
          <div class="d-flex align-center justify-center ml-md-8 fast-actions">
            <router-link to="/">
              <v-btn
                color="primary"
                large
                class="font-weight-bold fast-actions-btn pa-5 font-12"
              >
                <img
                  src="@/assets/img/svgs//home.svg"
                  alt="Home Icon"
                  class="mx-2"
                  loading="lazy"
                />
                الرئيسيه
              </v-btn>
            </router-link>
          </div>
        </div>
        <!-- error__descp__div -->
      </div>
      <!-- error__page__content -->
    </div>
    <!-- error__page -->
  </div>
  <!-- page main bg  -->
</template>
<script>
export default {
  name: 'ErrorPage',
  // components: {
  //  ErrorPage
  // },
  data: () => {
    return {
      breadcrumbs: [
        {
          text: 'Error Page',
          disabled: true,
          to: {
            path: '/error-page'
          }
        }
      ]
      // breadcrumbs
    }
  },
  methods: {}
}
//   export
</script>
<style scoped lang="scss">
.page-main-bg {
  height: 100%;
  .error__page {
    height: 100%;
    .error__page__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fast-actions-btn {
        border: 2px solid #efebf2;
        border-radius: 15px;
      }
    }
    // error__page__content
  }
  // error__page
}
</style>
